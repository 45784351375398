import React from 'react';
import "./../styles/main.min.css"
import Navbary from "./navbar"
import ReactBody from "./react_body"
import {Col, Row} from 'react-bootstrap'
import analytics from "./analytics"

// Globals

class Settings extends React.Component {

  // The component's Local state.
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}
  render() {
    analytics.logEvent('View Settings')

    return (
      <ReactBody color={true}>
        <Col>
        <Navbary></Navbary>
        <Col
        lg={{span:4, offset: 4}}
        md={{span:6, offset: 3}}
        sm={{span:6, offset: 3}}
        xs={{span:6, offset: 3}}
        >

          <Row className="mt-5">
          <Col className="text-center">Coming soon!</Col>
          </Row>
        </Col>
        </Col>
      </ReactBody>
    )
  }
}

export default Settings;

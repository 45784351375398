import React from 'react';
import 'firebase/auth';
import "./../styles/main.min.css"
import Navbary from './navbar'
import {Col, Row, Card, Image} from 'react-bootstrap'
import ReactBody from './react_body'
import analytics from "./analytics"

class News extends React.Component {

  // The component's Local state.
  state = {
    appname: "Chances"
  };

  render() {
    analytics.logEvent('View News')
    return (
      <ReactBody color={true}>
        <Col className="news">
        <Navbary></Navbary>
          <Row>
          <Col
          lg={{span:8, offset: 2}}
          md={{span:8, offset: 2}}
          xs={{span:12}}
          >
            <Card>
              <Row>
                <Col className="image-head">
                  <Image
                  className="image-fluid"
                  src="images/chances_logo.png"
                  fluid
                />
                </Col>
              </Row>
              <Card.Body>
                <Card.Title>First Launch!</Card.Title>
                <Card.Text>
                Hi Everyone,
                </Card.Text>
                <Card.Text>
                Welcome to the alpha launch of the Chances dating app.
                Around 50% of the app has been completed and we'd like to start
                gathering people and feedback for the public beta release in the
                fall. As part of the alpha group, you'll be able to see the newest
                features before everyone else and give input on how the app
                should be built before it becomes public.
                </Card.Text>
                <Card.Text>
                The goal behind chances is to provide information and coaching to help you
                achieve your dating goals. So far, we've implemented basic web app and support features
                to make developing the rest of the app much faster. Up next, we're adding profiles,
                matching, chat, and a learning/coaching center. Let us know if you have any
                other feature ideas in the feedback section of the account menu.
                </Card.Text>
            </Card.Body>
            <Card.Footer>
            <small className="text-muted">Last updated 6/8/2019</small>
          </Card.Footer>
            </Card>
          </Col>
          </Row>
        </Col>
      </ReactBody>
    );
  }
}

export default News;

import React, { Component } from 'react';
import "./styles/main.min.css"
import {
  BrowserRouter as Router,
  Route,
  Switch } from "react-router-dom";
import analytics from "./components/analytics"
import {Container} from 'react-bootstrap'
import SignInScreen from "./components/signinscreen";
import About from "./components/about"
import Home from "./components/home"
import PrivateRoute from "./components/private_route"
import Landing from "./components/landing"
import Chatbox from "./components/chatbox"
import Quiz from "./components/quiz"
import Account from "./components/account"
import Settings from "./components/settings"
import News from "./components/news"

analytics.logEvent('Download App')

// Load order: index.html > index.js > App > Router > component_x

class App extends Component {
  render() {
    // Switch causes route matching to stop after the first route is matched
    return (
      <Container>
      <Router>
        <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/signin" exact component={SignInScreen} />
            <Route path="/about" exact component={About} />
            <PrivateRoute path="/chat" exact component={Chatbox} />
            <PrivateRoute path="/settings" exact component={Settings} />
            <PrivateRoute path="/home" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/quiz" exact component={Quiz} />
            <PrivateRoute path="/account" exact component={Account} />
            <Route path="/news" exact component={News} />
        </Switch>
      </Router>
      </Container>
    );
  }
}


export default App;

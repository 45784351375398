import React from 'react';
import 'firebase/auth';
import "./../styles/main.min.css"
import Navbary from './navbar'
import ReactBody from './react_body'
import {Card, Col, Row, Image} from 'react-bootstrap'
import analytics from "./analytics"

class Home extends React.Component {

  // The component's Local state.
  state = {
    appname: "Chances"
  };

  render() {
    analytics.logEvent('View Home')
    return (
      <ReactBody color={true}>
        <div className="col home">
        <Navbary></Navbary>
        <Row className="h-100" >
        <Col
        className="h-100"
        lg={{span:8, offset: 2}}
        md={{span:8, offset: 2}}
        xs={{span:12}}
        >
          <Card className="h-90 text-center">
            <Card.Body>
              <Card.Text>
                Welcome to Chances!
              </Card.Text>
              <Card.Text>
                <Image src="images/chances_logo.png" className="icon" />
              </Card.Text>
              <Card.Text>
              We're still waiting for a few people to join.
              In the mean time, check out our news page.
              </Card.Text>
              <Card.Text>
              You can help speed things up by copying and sharing our link on social media.
              </Card.Text>
              <Card.Text>
                  https://datingchances.com/
              </Card.Text>
              <Card.Text>
              <a href="https://www.facebook.com/">
                <Image src="images/svg/logo-facebook.svg" className="icon" />
              </a>
              <a href="https://twitter.com/">
                <Image src="images/svg/logo-twitter.svg" className="icon" />
              </a>
              <a href="https://www.reddit.com/">
                <Image src="images/svg/logo-reddit.svg" className="icon" />
              </a>
              <a href="https://www.instagram.com/">
                <Image src="images/svg/logo-instagram.svg" className="icon" />
              </a>
              </Card.Text>
          </Card.Body>
          </Card>
        </Col>
        </Row>
        </div>
      </ReactBody>
    );
  }
}

export default Home;

// Firetools is an initialized firebase instance
import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCBMTL6AgILZ8hl3xrpUMjmRU54b_CqBMA",
  authDomain: "chances-ncg.firebaseapp.com",
  databaseURL: "https://chances-ncg.firebaseio.com",
  projectId: "chances-ncg",
  storageBucket: "chances-ncg.appspot.com",
  messagingSenderId: "439304024806",
  appId: "1:439304024806:web:30bc988608a433d2e08127",
  measurementId: "G-4565F4HKCD"
};

// Global Init
firebase.initializeApp(config)

const firestore = firebase.firestore()
const settings = {
  timestampsInSnapshots: true
}
firestore.settings(settings)

const firetools = firebase

export default firetools

// Modify the body element harmoniously with the React lifecycle
// Related scss in \src\styles\_defaults.scss
import React from 'react';

class ReactBody extends React.Component {

  // The component's Local state.
  state = {};

  static defaultProps = {
    color : true
  }
  componentDidMount() {
    var r = document.getElementById("root");
    if (this.props.color) {
      document.body.classList.toggle('white-back', this.props.color)
      r.classList.toggle('white-back', this.props.color)
    }
    else {
      document.body.classList.toggle('red-back', !this.props.color)
      r.classList.toggle('red-back', !this.props.color)
    }
  }

  // Leave the elements in a clean state for other components
  componentWillUnmount() {
    var r = document.getElementById("root");
    if (this.props.color) {
      document.body.classList.remove('white-back')
      r.classList.remove('white-back')
    }
    else {
      document.body.classList.remove('red-back')
      r.classList.remove('red-back')
    }
  }
  render() {
    return this.props.children
  }
}

export default ReactBody;

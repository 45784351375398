// A collection of strings, so that they can all be changed in one place
// Sorted alphabetically by variable name
import config from './config';
var strs = {
  appname: "Chances",
  slogan: "Your virtual dating coach!",
  version: config.version.join(".")
}

export default strs

import React from 'react';
import "./../styles/main.min.css"
import firetools from './firetools'
import analytics from "./analytics"

// Globals
var db = firetools.firestore()

class Chatbox extends React.Component {

  // The component's Local state.
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}
  render() {
    analytics.logEvent('View Chat')

    db.collection("users").add({
      first: "Ada",
      last: "Lovelace",
      born: 1815
    })
    .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
    return (
      <div>
        Chat Loaded
      </div>
    );
  }
}

export default Chatbox;

import React from 'react';
import "./../styles/main.min.css";
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import firetools from "./firetools";
import { BrowserRouter as Route, Redirect, Switch } from "react-router-dom";
import Home from "./home"
import strs from '../strings.js';
import analytics from "./analytics"

// Globals
let deferredPrompt;

function setCookie(cname,cvalue,exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

class SignInScreen extends React.Component {

  // The component's Local state.
  state = {
    isSignedIn: false,
    show_install: "none"
  };
  // Configure FirebaseUI.
  // Docs: https://github.com/firebase/firebaseui-web#configuration
  uiConfig = {
    // For Google/Facebook, redirect users to credential provider's site
    signInFlow: 'redirect',
    // Determines which sign in options are displayed
    signInOptions: [
      firetools.auth.EmailAuthProvider.PROVIDER_ID
    ],
    // Determines if account chooser is onAuthStateChanged
    credentialHelper: 'none',
    // These callbacks are run before signing in
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firetools.auth().onAuthStateChanged(
        (user) => {
          if (!!user){
            setCookie("refreshToken", user.refreshToken, 6500)
            this.setState({isSignedIn:!!user})
          }
        }
    );
    // // Homescreen install prompt
    window.addEventListener('beforeinstallprompt', (e) => {
      this.setState({show_install: 'block'});
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  install(){
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Install permission granted.');
        } else {
          console.log('Install permission denied.');
        }
        deferredPrompt = null;
      });
  }
  render() {
    if (this.state.isSignedIn){
      analytics.logEvent('login')
      return (
          <Switch>
            <Redirect push from="/" to="/home"/>
            <Route path="/home" component={Home}/>
          </Switch>
      );
    }
    else{
      return (
        <div className="col">
        <div className="signedin">
        <h1 className="center-white">
        {strs.appname}
        </h1>
        <p className="center-white">
        {strs.slogan}
        </p>
        <FirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firetools.auth()}/>
        </div>
        </div>
      );
    }
  }
}

export default SignInScreen;

import React from 'react';
import Navbary from "./navbar"
import ReactBody from "./react_body"
import {Button, Col, Row} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import "./../styles/main.min.css"
import analytics from "./analytics"

// Globals

class Account extends React.Component {

  // The component's Local state.
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}
  // (User photo) (coming soon)
  // Profile (coming soon)
  // News (coming soon)
  render() {
    analytics.logEvent('View Account')
    return (
      <ReactBody color={true}>
        <Col>
        <Navbary></Navbary>
        <Col
        lg={{span:4, offset: 4}}
        md={{span:6, offset: 3}}
        sm={{span:6, offset: 3}}
        xs={{span:6, offset: 3}}
        >
          <Row className="mt-5">
          <Button
          block
          variant="outline-primary"
          className="mx-auto"
          size="lg"
          href="https://forms.gle/aiVtgPrMNR7d7XD47">
          Feedback
          </Button>
          </Row>
          <Row className="mt-5">
          <LinkContainer to="/about">
          <Button
          block
          variant="outline-primary"
          className="mx-auto"
          size="lg"
          >
          About
          </Button>
          </LinkContainer>
          </Row>
          <Row className="mt-5">
          <LinkContainer to="/settings">
          <Button
          block
          variant="outline-primary"
          className="mx-auto"
          size="lg"
          >
          Settings
          </Button>
          </LinkContainer>
          </Row>
          <Row className="mt-5">
          <Button
          block
          variant="outline-primary"
          className="mx-auto"
          size="lg"
          href="https://chancessupport.freshdesk.com/support/tickets/new">
          Contact
          </Button>
          </Row>
        </Col>
        </Col>
      </ReactBody>
    )
  }
}

export default Account;

import React from 'react';
import "./../styles/main.min.css";
import {Nav} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'

class Navbary extends React.Component {
  state = {
    appname: "Chances"
  }

    render(){
      return (
            <Nav className="justify-content-center navbary" varient="pills">
              <LinkContainer to="/news"
              className="navbar"
              activeStyle={{fontWeight: "bold"}}>
                <Nav>News</Nav>
              </LinkContainer>
              <LinkContainer to="/home"
              className="navbar"
              activeStyle={{fontWeight: "bold"}}>
                <Nav>Chances</Nav>
              </LinkContainer>
              <LinkContainer to="/account"
              className="navbar"
              activeStyle={{fontWeight: "bold"}}>
                <Nav>Account</Nav>
              </LinkContainer>
            </Nav>
      );
    }
}

export default Navbary

import React from 'react';
import "./../styles/main.min.css"
import { BrowserRouter as Route, Redirect, Switch,Link } from "react-router-dom";
import strs from '../strings.js';
import Home from './home';
import {Button, Carousel, Col, Container, Image, Row} from 'react-bootstrap'
import ReactBody from './react_body'
import analytics from "./analytics"

class Landing extends React.Component {

  // The component's Local state.
  state = {
    isSignedIn: false,
    show_install: "none"
  };

  componentDidMount() {}

  componentWillUnmount() {}
  render() {
    analytics.logEvent('View Landing')
    if (this.state.isSignedIn){
      return (
          <Switch>
            <Redirect push from="/" to="/home"/>
            <Route path="/home" component={Home}/>
          </Switch>
      );
    }
    // @TODO: Find a better solution for the white text on an image
    else{
      return (
        <ReactBody color={false}>
        <Container className="landing">
        <Row>
          <Col
          lg={{span:4, offset: 4}}
          md={{span:6, offset: 3}}
          sm={{span:8, offset: 2}}
          xs={{span:10, offset: 1}}
          >
            <div>
              <h1 className="center-white">
                {strs.appname}
              </h1>
              <p className="center-white">{strs.slogan}</p>
            </div>
            <Carousel
              interval={8000}
            >
              <Carousel.Item>
                <div className="center">
                  <Image
                  className="phone_image"
                  rounded
                  src="images/1.jpg"
                  alt="Talk to local singles"
                  ></Image>
                </div>
                <Carousel.Caption>
                  <h3 className="onimage-text">Learn</h3>
                  <p className="onimage-text">
                    Dating is a skill that you can improve and we can help!
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="center">
                  <Image
                  className="phone_image"
                  rounded
                  src="images/2.jpg"
                  alt="Go on dates"
                  ></Image>
                </div>
                <Carousel.Caption>
                  <h3 className="onimage-text">Talk</h3>
                  <p className="onimage-text">
                    Skip the small talk with introductions and feedback from Chances.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="center">
                  <Image
                  className="phone_image"
                  rounded
                  src="images/3.jpg"
                  alt="Features"
                  ></Image>
                </div>
                <Carousel.Caption>
                  <h3 className="onimage-text">Singles Outings</h3>
                  <p className="onimage-text">
                    Pick an activity and meet people who share your interests.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
            <Button block>
              <Link to={"/signin"}>
                 Sign Up!
              </Link>
            </Button>
          </Col>
        </Row>
        </Container>
        </ReactBody>
      );
    }
  }
}



export default Landing;

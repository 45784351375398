import React from 'react';
import "./../styles/main.min.css"
import {Col, Row, Card} from 'react-bootstrap'
import Navbary from './navbar'
import ReactBody from './react_body'
import analytics from "./analytics"

class About extends React.Component {

  // The component's Local state.
  state = {
    appname: "Chances"
  };

  render() {
    analytics.logEvent('View About')
    return (
      <ReactBody color={true}>
        <Col className="news">
        <Navbary></Navbary>
          <Row>
          <Col
          lg={{span:8, offset: 2}}
          md={{span:8, offset: 2}}
          xs={{span:12}}
          >
            <Card>
              <Card.Body>
                <Card.Text>
                <div className="col-12">
                  <strong>
                    What problem does Chances solve?
                  </strong>
                  <p>
                    Wouldn't it be nice to know when you're about to say something stupid?
                     Do you ever wish that someone would just tell you some basic dating advice that actually works?
                      Do you ever wonder what's "normal" in a relationship?
                       Have you ever been rejected, but didn't understand why?
                        Chances aims to solve these problems.
                  </p>
                </div>
                <div className="col-12">
                  <strong>
                  Why you should use Chances instead of other dating apps?
                  </strong>
                  <p>
                  Chances focuses on providing more personal feedback and social interaction than other apps.
                  As a result, you'll learn more about yourself and about others, than you would with other dating apps.
                  The first feature you'll notice is that we pay attention to the whole dating process from asking your dating goals to asking about your last date.
                  By combining your game with Chances, you'll start having more fulfilling  and exciting dates in just a few months.
                  </p>
                </div>
                <div className="col-12">
                  <strong>
                  How will it work?
                  </strong>
                  <p>
                  After you create your profile, you'll be able to find someone to chat with.
                   If you're about to say something horribly misguided, then Chances will warn you before you send it.
                    When you go on a date, Chances will ask you some questions and offer advice before and after.
                     After a few dates, Chances will learn your style and offer advice.
                  </p>
                </div>
                <div  className="col-12">
                <strong>Where does the advice come from?</strong>
                <p> Chances sends your data to our artificially intelligent computer programs.
                 Think of this as your own personal team of love doctors! They use statistics to analyze hundreds of dates to learn what works best and make recommendations for everyone.
                  Chances then takes their recommendations and sends them to you.
                   By rigorously analyzing data from hundreds of dates, Chances is able to give you better advice than any one person would be able to give.
                    </p>
                </div>
                <div  className="col-12">
                <strong>How secure is your data?</strong>
                <p> Chances follows industry standard security measures and runs on Google's infrastructure.
                 So, your data will stay encrypted, private, and safe.
                  </p>
                </div>
                <div className="col-12">
                  <strong>
                  What features are planned so far?
                  </strong>
                  <ul>
                    <li>Basics: profiles, matching, chat</li>
                    <li>Skill Focus: pre/post date coaching, custom tips, public surveys, chat analysis</li>
                    <li>Science: research-based tips, ML/Data Science tips, ML matching</li>
                    <li>Crowd Control: reporting, spam filters, location filtering, privacy</li>
                  </ul>
                </div>
                </Card.Text>
            </Card.Body>
            </Card>
          </Col>
          </Row>
        </Col>
      </ReactBody>
    );
  }
}

export default About;

import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// Redirects a user to a pathname if the page they're accessing requires a login.
// Warning: Infinite loop will occur if you don't pay attention to memory state
function PrivateRoute({ component: Component }) {
  var refreshToken = getCookie("refreshToken");
  return (
    <Route
      render={props =>
        !!refreshToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;

import React from 'react';
import "./../styles/main.min.css"
import ReactBody from './react_body.js'
import {Col, Container, Row} from 'react-bootstrap'
import analytics from "./analytics"
// Globals

class Quiz extends React.Component {

  // The component's Local state.
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}
  render() {
    analytics.logEvent('View Quiz')
    return (
      <ReactBody color={true}>
      <Container>
      <Row>
        <Col
        lg={{span:4, offset: 4}}
        md={{span:6, offset: 3}}
        sm={{span:8, offset: 2}}
        xs={{span:10, offset: 1}}
        >

        </Col>
      </Row>
      </Container>
      </ReactBody>
    )
  }
}

export default Quiz;
